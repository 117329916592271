<script lang="ts">
  import { ZodError, set } from "zod";
  import { sendContact, IContact, getContactSchema } from "@services/contact.ts";
  import { useTranslations } from "@i18n/utils.ts";

  export let lang: "es-CO" | "en"  = "en";

  let errors: any = {};

  const contactReset: IContact = {
    name: "",
    email: "",
    message: "",
    phone: "",
    reason: ""
  };

  let contact: IContact = {
    name: "",
    email: "",
    message: "",
    phone: "",
    reason: ""
  };

  const __e = useTranslations(lang);

  const contactSubmit = async () => {
    document.querySelector("#btnsend")?.classList.add("disabled");

    try {
      const ContactSchema = getContactSchema(lang);
      ContactSchema.parse(contact);

      contact.metadata = {
            "userAgent": window.navigator.userAgent,
            "from": "From Contact Footer",
            "navigatorLang": window.navigator.language,
            "lang": lang
        };
      await sendContact(contact);
      errors = {};
      document.querySelector("#messagesend")?.classList.remove("hidden");
      contact = {...contactReset};
      
      setTimeout(() => {
        document.querySelector("#btnsend")?.classList.remove("disabled");
        document.querySelector("#messagesend")?.classList.add("hidden");
      }, 4000);
    } catch (error: any | ZodError) {
      if (error instanceof ZodError) {
        error
      }

      errors = error.issues.reduce((acc: any, err: any) => {
            return { ...acc, [err.path[0]]: err.message };
          }, {});

      document.querySelector("#btnsend")?.classList.remove("disabled");
      document.querySelector("#messagesend")?.classList.add("hidden");
    }
  };

</script>

<div id="contact" class="mg-contact-form-container {lang}">
  <div class="mg-contat-text-col wow fadeIn"  data-wow-delay="0.4s">
    <div class="contact-section-title w-full relative max-[460px]:px-[65px] max-[460px]:pb-5 z-10 pt-5 pr-5 pb-24 pl-44 max-[1060px]:pl-16">
      <h3 class="mb-4 font-extrabold max-sm:text-4xl max-[864px]:text-4xl text-5xl text-white max-lg:text-6xl">{__e('page.contact.title')}</h3>
      <p class="font-extralight text-5xl text-white max-[864px]:text-2xl max-[1430px]:text-3xl">{__e('page.contact.sectionDescription')}</p>
    </div>
  </div>

  <div class="mg-contact-frm-col mb-60 max-[460px]:mb-40">
    <div class="mg-contat-frm-title mb-5 wow fadeIn">
      <h3 class="text-dark-500 text-lg mb-2">
        {__e('page.contact.connect')}
      </h3>
      <p class="text-dark-500">
        {__e('page.contact.receiveMessages')}
      </p>
    </div>

    <div class="mg-contact-frm-form">
      <form
        on:submit|preventDefault={contactSubmit}
        autocomplete="off"
        class="xlg:pr-40 lg:pr-20 max-w-sm:pr-0 max-md:pr-0 z-10 wow fadeIn" data-wow-delay="0.3s">
        <h4 class="text-dark-500 mb-5 font-bold text-xl">{__e('page.contact.sendMessage')}</h4>

        <div class="mg-control relative pt-2 mb-4">
          <label for="name" class="mg-label block mb-2 absolute -top-2 p-1 left-2 bg-dark-50 text-sm font-light text-dark-900">{__e('page.contact.name')}</label>
          <input
            type="text"
            bind:value={contact.name}
            class="mg-input bg-dark-50 border rounded-none focus:outline-none focus-visible:outline-none border-dark-300 text-dark-900 text-sm focus:ring-meridianblue-500 focus:border-meridianblue-500 block w-full p-2.5"
            name="name"
            placeholder={__e('page.contact.placeholderName')}
          />
          {#if errors.name}
            <span class="error">{errors.name}</span>
          {/if}
        </div>

        <div class="mg-control relative pt-2 mb-4">
          <label for="phone" class="mg-label block mb-2 absolute -top-2 p-1 left-2 bg-dark-50 text-sm font-light text-dark-900">{__e('page.contact.phone')}</label>
          <input
            type="tel"
            class="mg-input bg-dark-50 border rounded-none focus:outline-none focus-visible:outline-none border-dark-300 text-dark-900 text-sm focus:ring-meridianblue-500 focus:border-meridianblue-500 block w-full p-2.5"
            bind:value={contact.phone}
            name="phone"
            placeholder={__e('page.contact.placeholderPhone')}
          />
        </div>

        <div class="mg-control relative pt-2 mb-4">
          <label for="email" class="mg-label block mb-2 absolute -top-2 p-1 left-2 bg-dark-50 text-sm font-light text-dark-900">{__e('page.contact.email')}</label>
          <input
            type="text"
            class="mg-input bg-dark-50 border rounded-none focus:outline-none focus-visible:outline-none border-dark-300 text-dark-900 text-sm focus:ring-meridianblue-500 focus:border-meridianblue-500 block w-full p-2.5"
            bind:value={contact.email}
            name="email"
            placeholder={__e('page.contact.placeholderEmail')}
          />

          {#if errors.email}
            <span class="error">{errors.email}</span>
          {/if}
        </div>

        <div class="mg-control relative pt-2 mb-4">
          <label for="message" class="mg-label block mb-2 absolute -top-2 p-1 left-2 bg-dark-50 text-sm font-light text-dark-900">{__e('page.contact.message')}</label>
          <textarea
            name="message"
            class="mg-input bg-dark-50 border rounded-none focus:outline-none focus-visible:outline-none border-dark-300 text-dark-900 text-sm focus:ring-meridianblue-500 focus:border-meridianblue-500 block w-full p-2.5"
            rows="10"
            bind:value={contact.message}
            placeholder={__e('page.contact.placeholderMessage')}
          />
          {#if errors.message}
            <span class="error">{errors.message}</span>
          {/if}
        </div>

        <button type="submit" id="btnsend" class="btn btn-primary px-8">{__e('page.contact.send')}</button>

        <div id="messagesend" class="hidden bg-meridianblue-100 border-t-4 mt-3 transition border-meridianblue-500 rounded-b text-dark-900 px-4 py-3 shadow-md" role="alert">
          <div class="flex">
            <div class="py-1"><svg class="fill-current h-6 w-6 text-meridianblue-500 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/></svg></div>
            <div>
              <p class="font-bold">{__e('page.contact.sent')}</p>
              <p class="text-sm">{__e('page.contact.contactMessageSent')}</p>
            </div>
          </div>
        </div>

      </form>

      <div class="mg-contact-info text-dark-500 z-10 wow fadeIn"  data-wow-delay="0.4s">
        <h4 class="text-dark-500 mb-5 font-bold text-xl">{__e('page.contact.innovationHub')}</h4>
        <h4 class="text-dark-500 mb-1 font-bold text-xl">Colombia</h4>
        <ul class="mg-dir-list">
          <!-- <li class="text-base font-extralight"><span>{__e('page.contact.phone')}</span> (601) 000 00 00</li> -->
          <li class="text-base font-extralight"><span>{__e('page.contact.mobile')}</span> (+57) 311 6013526</li>
          <!-- <li class="text-base font-extralight"><span>{__e('page.contact.fax')}</span> (601) 000 00 00</li> -->

          <li class="text-base font-extralight mt-3"><span>{__e('page.contact.address')}</span></li>
          <li class="text-base font-extralight">Carrera 21 150 - 72 Bogotá, Colombia</li>
        </ul>

        <h4 class="text-dark-500 mb-1 mt-5 font-bold text-xl">USA</h4>
        <ul class="mg-dir-list">
          <li class="text-base font-extralight"><span>{__e('page.contact.phone')}</span>  +1 408 306 9878</li>
          <!-- <li class="text-base font-extralight"><span>{__e('page.contact.mobile')}</span> 0000 000 00 00</li>
          <li class="text-base font-extralight"><span>{__e('page.contact.fax')}</span> (601) 000 00 00</li> -->

          <li class="text-base font-extralight mt-3"><span>{__e('page.contact.address')}</span></li>
          <li class="text-base font-extralight">532 Fern Ridge Ct</li>
          <li class="text-base font-extralight">Sunnyvale, CA 94087</li>
        </ul>
      </div>

      <div class="mg-spaceman"></div>

    </div>
  </div>
</div>

<style>
  .disabled {
    pointer-events: none;
    opacity: 0.2;
  }

  .error {
    color: red;
    display: block;
    padding: 5px;
  }
  .mg-contact-form-container {
    display: grid;
    grid-template-columns: minmax(0, 40%) 1fr;
    gap: 0px;
    width: 100%;
    min-height: 795px;
  }
  .mg-contat-text-col {
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 300px;
  }
  .mg-contat-text-col:after {
    content: "";
    width: 100%;
    min-height: 795px;
    position: absolute;
    z-index: 0;
    background-image: url(/assets/smoke.webp);
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: 40%;
    overflow: hidden;
    top: -90px;
  }
  .contact-section-title{
    position: relative;
  }
  .contact-section-title:before {
    content: "";
    display: block;
    position: absolute;
    left: 115px;
    top: 36px;
    width: 25px;
    height: 145px;
    background-color: #0AB2C5;
  }

  .mg-contact-form-container .mg-contact-frm-col {
    background-color: #e4e4e4;
    display: flex;
    flex-direction: column;
    padding: 25px 50px;
    position: relative;
  }

  .mg-spaceman {
    width: 100%;
    height: 900px;
    z-index: 0;
    display: block;
    position: absolute;
    overflow: hidden;
    bottom: -272px;
    right: 0;
    pointer-events: none;
  }
  .mg-spaceman:after {
    content: "";
    display: block;
    position: relative;
    background-image: url(/assets/spaceman-on-rock-edge.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right;
    width: 100%;
    height: 900px;
    right: -439px;
    z-index: 0;
  }

  .mg-contact-form-container .mg-contat-frm-title {
    margin-left: 50px;
    max-width: 700px;
    text-align: center;
  }

  .mg-contat-frm-title h3 {
    font-size: 40px;
    line-height: 40px;
    font-weight: bold;
  }

  .mg-contat-frm-title p {
    font-size: 16px;
    line-height: 24px;
    font-weight: 200;
  }

  .mg-contact-frm-form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
  }

  @media (max-width: 895px) {
    .contact-section-title:before {
      left: 22px;
    }

    .mg-spaceman {
      bottom: -63px;
      right: 0;
    }
    .mg-spaceman:after {
      background-size: contain;
    background-position: right;
    width: 100%;
    height: 1467px;
    right: -128px;
    z-index: 0;

    }
  }

  @media screen and (max-width: 660px) {
    .mg-contact-form-container {
      grid-template-columns: auto;
    }

    .mg-contat-text-col {
      margin-bottom: 60px;
    }

    .mg-contat-text-col:after {
      display: none;
    }

    /* .mg-contat-text-col .mg-contact-text {
      padding: 20px 30px 20px 30px;
    } */
    .mg-contat-text-col .mg-contact-text h3 {
      margin-top: 50px;
      font-size: 60px;
    }

    .mg-contat-text-col .mg-contact-text p {
      font-size: 24px;
    }

    .mg-contact-frm-form {
      grid-template-columns: auto;
    }

    .mg-contact-frm-form  form{
      padding-right: 0px;
      z-index: 0;
    }

    .mg-contact-form-container .mg-contat-frm-title {
      margin-left: 0px;
      max-width: 100%;
    }
    .mg-contact-form-container .mg-contact-frm-col {
      padding: 25px 50px 69px 50px;
    }

    .contact-section-title:before {
    content: "";
    left: 30px;
    top: 36px;
    width: 25px;
    height: 150px;
  }

    .mg-spaceman:after {
      content: "";
    background-image: url(/assets/spaceman-on-rock-mobile.png);
    background-size: 68%;
    width: 100%;
    height: 900px;
    right: -64px;
    top: 35%;
    }
  }
</style>
